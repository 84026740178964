const Lounge = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="35px"
      height="24.5px"
      viewBox="0 0 35 24.5"
      style={{
        overflow: "visible",
        enableBackground: "new 0 0 35 24.5",
        fill: "#ffffff",
      }}
    >
      <defs></defs>
      <g>
        <g id="CPVCFr.tif_3_">
          <g>
            <path
              className="st0"
              d="M29.6,21.1c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0c0.9,0,1.6-0.4,2.1-1.1c0.2-0.3,0.3-0.6,0.4-0.9
				c0.9-2.1,1.5-4.3,2.1-6.6c0.2-0.6,0.3-1.3,0.4-1.9c0.1-0.5-0.2-0.7-0.7-0.7c-0.5,0-1,0-1.4,0c-0.4,0-0.6,0.1-0.9,0.4
				c-0.6,0.9-1.3,1.7-2,2.6c-1.4,1.7-3.2,2.8-5.4,3c-0.9,0.1-1.9,0.1-2.8,0.1c-0.2,0-0.5,0-0.7,0.1c-0.9,0.3-1.3,1.1-1.2,2.1
				c0.2,1.5,1.1,2.5,2.6,2.9c0.2,0.1,0.3,0.1,0.2,0.3c-0.1,0.6-0.1,1.1-0.2,1.7c-0.1,0.5-0.1,1-0.2,1.5c0.3,0,0.6,0,0.8,0
				c0.2,0,0.2-0.1,0.3-0.2c0.2-0.6,0.4-1.2,0.5-1.8c0.1-0.2,0.2-0.3,0.4-0.3c1.3,0,2.7,0,4,0c0.2,0,0.3,0.1,0.5,0.2
				c0.5,0.6,1,1.3,1.5,1.9c0.1,0.1,0.2,0.2,0.2,0.2c0.3,0,0.7,0,1,0C30.9,23.4,30.3,22.3,29.6,21.1z"
            />
            <path
              d="M29.6,21.1c0.6,1.1,1.3,2.2,1.9,3.4c-0.4,0-0.7,0-1,0c-0.1,0-0.2-0.1-0.2-0.2c-0.5-0.6-1-1.3-1.5-1.9
				c-0.1-0.2-0.3-0.2-0.5-0.2c-1.3,0-2.7,0-4,0c-0.3,0-0.3,0.1-0.4,0.3c-0.2,0.6-0.3,1.2-0.5,1.8c0,0.2-0.1,0.2-0.3,0.2
				c-0.3,0-0.5,0-0.8,0c0.1-0.5,0.1-1,0.2-1.5c0.1-0.6,0.1-1.1,0.2-1.7c0-0.2,0-0.3-0.2-0.3c-1.5-0.3-2.4-1.4-2.6-2.9
				c-0.1-1,0.3-1.8,1.2-2.1c0.2-0.1,0.5-0.1,0.7-0.1c0.9,0,1.9,0,2.8-0.1c2.2-0.2,4-1.3,5.4-3c0.7-0.8,1.3-1.7,2-2.6
				c0.2-0.3,0.5-0.4,0.9-0.4c0.5,0,1,0,1.4,0c0.5,0,0.7,0.2,0.7,0.7c-0.1,0.6-0.2,1.3-0.4,1.9c-0.5,2.2-1.2,4.4-2.1,6.6
				c-0.1,0.3-0.3,0.6-0.4,0.9c-0.5,0.7-1.2,1.1-2.1,1.1c-0.1,0-0.2,0-0.3,0C29.7,21.1,29.7,21.1,29.6,21.1z"
            />
          </g>
        </g>
        <g id="CPVCFr.tif_2_">
          <g>
            <path
              className="st0"
              d="M5.4,21.1c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3,0c-0.9,0-1.6-0.4-2.1-1.1c-0.2-0.3-0.3-0.6-0.4-0.9
				c-0.9-2.1-1.5-4.3-2.1-6.6c-0.2-0.6-0.3-1.3-0.4-1.9c-0.1-0.5,0.2-0.7,0.7-0.7c0.5,0,1,0,1.4,0c0.4,0,0.6,0.1,0.9,0.4
				c0.6,0.9,1.3,1.7,2,2.6c1.4,1.7,3.2,2.8,5.4,3c0.9,0.1,1.9,0.1,2.8,0.1c0.2,0,0.5,0,0.7,0.1c0.9,0.3,1.3,1.1,1.2,2.1
				C15,19.6,14,20.6,12.6,21c-0.2,0.1-0.3,0.1-0.2,0.3c0.1,0.6,0.1,1.1,0.2,1.7c0.1,0.5,0.1,1,0.2,1.5c-0.3,0-0.6,0-0.8,0
				c-0.2,0-0.2-0.1-0.3-0.2c-0.2-0.6-0.4-1.2-0.5-1.8c-0.1-0.2-0.2-0.3-0.4-0.3c-1.3,0-2.7,0-4,0c-0.2,0-0.3,0.1-0.5,0.2
				c-0.5,0.6-1,1.3-1.5,1.9c-0.1,0.1-0.2,0.2-0.2,0.2c-0.3,0-0.7,0-1,0C4.1,23.4,4.7,22.3,5.4,21.1z"
            />
            <path
              d="M5.4,21.1c-0.6,1.1-1.3,2.2-1.9,3.4c0.4,0,0.7,0,1,0c0.1,0,0.2-0.1,0.2-0.2c0.5-0.6,1-1.3,1.5-1.9
				c0.1-0.2,0.3-0.2,0.5-0.2c1.3,0,2.7,0,4,0c0.3,0,0.3,0.1,0.4,0.3c0.2,0.6,0.3,1.2,0.5,1.8c0,0.2,0.1,0.2,0.3,0.2
				c0.3,0,0.5,0,0.8,0c-0.1-0.5-0.1-1-0.2-1.5c-0.1-0.6-0.1-1.1-0.2-1.7c0-0.2,0-0.3,0.2-0.3c1.5-0.3,2.4-1.4,2.6-2.9
				c0.1-1-0.3-1.8-1.2-2.1C13.7,16,13.4,16,13.2,16c-0.9,0-1.9,0-2.8-0.1c-2.2-0.2-4-1.3-5.4-3c-0.7-0.8-1.3-1.7-2-2.6
				C2.8,10,2.5,9.8,2.1,9.9c-0.5,0-1,0-1.4,0c-0.5,0-0.7,0.2-0.7,0.7c0.1,0.6,0.2,1.3,0.4,1.9c0.5,2.2,1.2,4.4,2.1,6.6
				c0.1,0.3,0.3,0.6,0.4,0.9C3.4,20.6,4.1,21,5,21.1c0.1,0,0.2,0,0.3,0C5.3,21.1,5.3,21.1,5.4,21.1z"
            />
          </g>
        </g>
        <rect x="16.9" y="12.7" width="1.4" height="11.8" />
        <rect x="28" y="1" width="1.5" height="18.1" />
        <rect x="5.8" y="1" width="1.5" height="18.1" />
        <path
          d="M11.2,13.3v-0.8c0-0.2,0.2-0.4,0.4-0.4h12c0.2,0,0.4,0.2,0.4,0.4v0.8c0,0.2-0.2,0.4-0.4,0.4h-12
		C11.4,13.6,11.2,13.5,11.2,13.3z"
        />
        <rect x="0.3" width="34.6" height="2" />
      </g>
    </svg>
  );
};

export default Lounge;
