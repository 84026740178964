const SmartHome = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="34.4px"
      height="28.2px"
      viewBox="0 0 34.4 28.2"
      style={{
        overflow: "visible",
        enableBackground: "new 0 0 35 28.7",
        fill: "#ffffff",
        strokeWidth: 0.8,
        strokeMiterlimit: 10,
      }}
    >
      <defs></defs>
      <path
        className="st0"
        d="M23.9,28.5c-1.4-1.4-2.9-2.9-4.3-4.3c-0.8-0.8-0.8-1.1,0-1.8c2.5-2.2,6.6-2.2,9.1,0c0.9,0.7,0.9,1.1,0.1,1.9
	c-1.4,1.4-2.8,2.9-4.2,4.3C24.4,28.5,24.2,28.5,23.9,28.5z M21.2,23.3c1.1,1,2.1,2,3.2,3.1c0.8-0.8,1.7-1.7,2.6-2.7
	c0.1-0.1,0.2-0.3,0.4-0.5C25.4,22,22.9,22,21.2,23.3z"
      />
      <path
        className="st0"
        d="M2.4,11.9c-0.4,0.3-0.6,0.6-0.8,0.6c-0.4,0.1-0.8,0-1.2,0c0.1-0.4,0-0.9,0.2-1.2c0.9-0.9,2-1.7,2.9-2.6
	c3.1-2.7,6.2-5.3,9.3-8c0.7-0.6,1.2-0.6,1.9,0c1,0.9,2,1.7,2.8,2.4c0.6-0.2,1.1-0.4,1.6-0.4c1-0.1,2.1,0,3.1,0
	c0.9,0,1.2,0.3,1.2,1.2c0,1.1,0,2.3,0,3.4c0,0.6,0.1,1,0.6,1.4c0.5,0.4,1.1,0.8,1.5,1.4c0.2,0.3,0.1,0.8,0.1,1.2
	c-0.4,0-0.9,0.2-1.1,0c-0.8-0.6-1.6-1.3-2.4-2c-0.2-0.2-0.4-0.7-0.4-1c0-1.3,0-2.5,0-3.8c-0.8,0-1.6,0-2.3,0c0,0.2,0,0.4,0,0.5
	c-0.1,0.3-0.3,0.8-0.5,0.9c-0.3,0.1-0.8-0.1-1-0.3c-1.4-1.1-2.7-2.3-4.1-3.5c-1.1,0.9-2.2,1.9-3.2,2.8c-2,1.7-4,3.4-5.9,5.2
	C4.3,10.2,4,10.7,4,11c0,4.1,0,8.2,0,12.3c0,0.1,0,0.3,0.1,0.5c0.4,0,0.7,0,1.1,0c3.9,0,7.7,0,11.6,0c0.3,0,0.6-0.1,0.8,0
	c0.3,0.2,0.7,0.5,0.7,0.7c0,0.3-0.4,0.6-0.7,0.8c-0.2,0.1-0.5,0-0.8,0c-4.3,0-8.6,0-12.9,0c-1.4,0-1.5-0.1-1.5-1.5
	c0-3.6,0-7.1,0-10.7C2.4,12.8,2.4,12.5,2.4,11.9z"
      />
      <path
        className="st0"
        d="M24.3,12.7c3.7,0.1,7,1.3,9.8,3.8c0.3,0.3,0.5,0.7,0.6,1c0,0.2-0.3,0.6-0.5,0.6c-0.3,0-0.8-0.1-1-0.3
	c-2-1.7-4.3-2.9-6.9-3.3c-3.8-0.6-7.3,0.4-10.3,2.8c-0.2,0.2-0.4,0.3-0.6,0.5c-0.4,0.4-0.9,0.7-1.4,0.2c-0.5-0.5-0.2-0.9,0.3-1.3
	c2-1.8,4.4-3,7.1-3.6C22.4,12.8,23.3,12.8,24.3,12.7z"
      />
      <path
        className="st0"
        d="M24.3,16.7c2.8,0,5.3,1,7.4,2.9c0.4,0.4,0.8,0.8,0.3,1.3c-0.5,0.5-0.9,0.3-1.4-0.1c-4-3.4-8.7-3.4-12.7,0
	c-0.4,0.4-0.9,0.6-1.3,0.1c-0.5-0.5-0.2-0.9,0.3-1.3C19,17.7,21.4,16.7,24.3,16.7z"
      />
    </svg>
  );
};

export default SmartHome;
