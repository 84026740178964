import { useRef, useEffect, useState } from "react";

import IMAGES from "./Images";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import useOnScreen from "../hooks/useOnScreen";

const Apartments = () => {
  const row1 = useRef();
  const row2 = useRef();
  const holder = useRef();

  const [transform, setTransform] = useState({ row1: 0, row2: 0 });
  const isVisible = useOnScreen(holder);
  useEffect(() => {
    row1.current.scrollLeft = 200;
    row2.current.scrollLeft = row2.current.scrollWidth + 200;
  }, [row1, row2]);

  useEffect(() => {
    if (isVisible) return;
    setTransform({
      row1: 0,
      row2: 0,
    });
  }, [isVisible]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const up = prevPos.y < currPos.y;
      if (isVisible) {
        const ratio = 2;
        if (up) {
          setTransform({
            row1: transform.row1 + ratio,
            row2: transform.row2 - ratio,
          });
        } else {
          setTransform({
            row1: transform.row1 - ratio,
            row2: transform.row2 + ratio,
          });
        }
      }
    },
    null,
    null,
    false,
    0
  );

  return (
    <div
      className="container-fluid apartments-container"
      id="apartments"
      ref={holder}
    >
      <div className="title">
        <h4>New & modern apartments</h4>
        <h6>
          <span>Studio</span>
          <span>1 Bedroom</span>
          <span>2 Bedroom</span>
        </h6>
      </div>
      <div className="image-row" ref={row1}>
        <div
          className="image-content"
          style={{ transform: `translateX(${transform.row1}px)` }}
        >
          {IMAGES[0].map((image, index) => {
            return (
              <div className="image" key={index}>
                <img src={image} alt={`apartment-${index}`} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="image-row" ref={row2}>
        <div
          className="image-content"
          style={{ transform: `translateX(${transform.row2}px)` }}
        >
          {IMAGES[1].map((image, index) => {
            return (
              <div className="image" key={index}>
                <img src={image} alt={`apartment-${index}`} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Apartments;
