import { useState } from "react";

import Lounge from "./Icons/Lounge";
import Furniture from "./Icons/Furniture";
import Gym from "./Icons/Gym";
import SmartHome from "./Icons/SmartHome";
import Maintenance from "./Icons/Maintenance";
import Swimming from "./Icons/Swimming";

import ImageModal from "./ImageModal";

const ITEMS = [
  {
    label: "Pool",
    icon: <Swimming />,
    color: `#B2513C`,
  },
  {
    label: "Gym",
    icon: <Gym />,
    color: `#623CB2`,
  },
  {
    label: "24/7 maintenance",
    icon: <Maintenance />,
    color: `#3C50B2`,
  },
  {
    label: "Designing services",
    icon: <Furniture />,
    color: `#3C8FB2`,
  },
  {
    label: "Outdoor lounge",
    icon: <Lounge />,
    color: `#B2753C`,
  },
  {
    label: "Smart home services",
    icon: <SmartHome />,
    color: `#A93CB2`,
  },
];

const Amenities = () => {
  const [images, showImages] = useState(false);
  const toggle = () => showImages(!images);
  return (
    <div className="container amenities-container" id="amenities">
      <div className="amenties-title">
        <h2 className="title">{`Building facilities & services`}</h2>
        {/* <p className="description">
          Enhance your stay and get access to our top notch exclusive services.
        </p> */}
      </div>

      <div className="items" style={{ marginTop: "30px" }}>
        {ITEMS.map((item, index) => {
          return (
            <div className="item" key={index}>
              <div className="item-content">
                <div
                  className="item-icon"
                  style={{ backgroundColor: item.color }}
                  onClick={() => showImages(true)}
                >
                  {item.icon}
                </div>
                <span className="item-label" style={{ color: item.color }}>
                  {item.label}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <ImageModal show={images} toggle={toggle}></ImageModal>
    </div>
  );
};

export default Amenities;
